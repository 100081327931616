import React from 'react'
import Layout from '../layouts'
import Swiper from 'swiper/dist/js/swiper.esm.bundle'
import 'swiper/dist/css/swiper.min.css'
import ListBG from '../images/architecture-bungalow.jpg'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { graphql } from 'gatsby'
import CommonHero from '../components/commonhero/commonhero.js'
import WebriQForm from '@webriq/gatsby-webriq-form'

class ListingDetails extends React.Component {
  componentDidMount() {
    if (window) {
      var galleryThumbs = new Swiper('.gallery-thumbs', {
        spaceBetween: 10,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
      })

      new Swiper('.gallery-top', {
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        thumbs: {
          swiper: galleryThumbs,
        },
      })
    }
  }

  render() {
    const data = this.props.data.sanityListing
    console.log(data)
    return (
      <Layout head={{ title: data.UnparsedAddress }}>
        <CommonHero
          pageTitle={data.UnparsedAddress}
          custombackground={ListBG}
        />
        <main className="bg-dirty-white">
          <section id="featured-listings" className="bg-white pt-5x">
            <div className="container py-5">
              <div className="swiper-container listing-gallery gallery-top">
                <div className="swiper-wrapper">
                  {data.Media.map(image => (
                    <div
                      className="swiper-slide"
                      style={{
                        backgroundImage: `url(${image.asset.url})`,
                        backgroundPosition: 'top left',
                      }}
                      key={image.asset.id}
                    />
                  ))}
                </div>
                <div className="swiper-button-next swiper-button-white" />
                <div className="swiper-button-prev swiper-button-white" />
              </div>
              <div className="swiper-container listing-gallery gallery-thumbs">
                <div className="swiper-wrapper">
                  {data.Media.map(image => (
                    <div
                      className="swiper-slide"
                      style={{
                        backgroundImage: `url(${image.asset.url})`,
                      }}
                      key={image.asset.id}
                    />
                  ))}
                </div>
              </div>

              <div className="my-5">
                <Tabs>
                  <TabList>
                    <Tab>Details</Tab>
                    <Tab>Main Features</Tab>
                    <Tab>Additional Features</Tab>
                    <Tab>Area Facts</Tab>
                    <Tab>Request More Info</Tab>
                  </TabList>

                  {/***** DETAILS *****/}
                  <TabPanel>
                    <h3 className="py-4 text-center">
                      Single Family in Colorado Springs
                    </h3>

                    <div className="py-5 text-center">
                      <div className="row m-0 p-0">
                        <div className="col-3 p-0 m-0">
                          <img
                            alt="bed"
                            src="/img/003-bed.svg"
                            width="50"
                            className="mr-1"
                          />
                          <span className="d-block">Beds</span>
                          <h4>
                            {data.BedroomsTotal
                              ? data.BedroomsTotal
                              : 'Not Specified'}
                          </h4>
                        </div>
                        <div className="col-3 p-0 m-0">
                          <img
                            alt="shower"
                            src="/img/001-shower.svg"
                            width="50"
                            className="mr-1"
                          />
                          <span className="d-block">Baths</span>
                          <h4>
                            {data.BathroomsTotalInteger
                              ? data.BathroomsTotalInteger
                              : 'Not Specified'}
                          </h4>
                        </div>
                        <div className="col-3 p-0 m-0">
                          <img
                            alt="area"
                            src="/img/004-real-estate.svg"
                            width="50"
                            className="mr-1"
                          />
                          <span className="d-block">SQ. FT.</span>
                          <h4>
                            {data.LotSizeSquareFeet
                              ? data.LotSizeSquareFeet
                              : 'Not Specified'}
                          </h4>
                        </div>
                        <div className="col-3 p-0 m-0">
                          <img
                            alt="area"
                            src="/img/005-acre.svg"
                            width="50"
                            className="mr-1"
                          />
                          <span className="d-block">Acres</span>
                          <h4>
                            {data.LotSizeAcres
                              ? data.LotSizeAcres
                              : 'Not Specified'}
                          </h4>
                        </div>
                      </div>
                    </div>

                    <p
                      key={`body`}
                      id="___gatsby"
                      dangerouslySetInnerHTML={{
                        __html: (data && data.description) || 'Not Specified',
                      }}
                    />
                    <p>{data.Agent ? data.Agent : ''}</p>
                  </TabPanel>

                  {/***** MAIN FEATURES *****/}
                  <TabPanel>
                    <h3 className="py-4 text-center">Main Features</h3>
                    <div className="row listing-detail-table">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-5">Listing Status:</div>
                          <div className="col-7">
                            {data.StandardStatus
                              ? data.StandardStatus
                              : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Year Built:</div>
                          <div className="col-7">
                            {data.YearBuilt ? data.YearBuilt : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Full Bath:</div>
                          <div className="col-7">
                            {data.BathroomsFull
                              ? data.BathroomsFull
                              : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Floor:</div>
                          <div className="col-7">
                            {data.Floor ? data.Floor : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">School District 1:</div>
                          <div className="col-7">
                            {data.SchoolDistrictFirst
                              ? data.SchoolDistrictFirst
                              : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">School District 2:</div>
                          <div className="col-7">
                            {data.SchoolDistrictSecond
                              ? data.SchoolDistrictSecond
                              : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">School District 3:</div>
                          <div className="col-7">
                            {data.SchoolDistrictThird
                              ? data.SchoolDistrictThird
                              : 'Not Specified'}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-5">County:</div>
                          <div className="col-7">
                            {data.City ? data.City : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Taxes:</div>
                          <div className="col-7">
                            {data.Taxes ? data.Taxes : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Tax Year:</div>
                          <div className="col-7">
                            {data.TaxYear ? data.TaxYear : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Association Fee:</div>
                          <div className="col-7">
                            {`${
                              data.AssociationFee
                                ? data.AssociationFee
                                : 'Not Specified'
                            }`}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Zoning:</div>
                          <div className="col-7">
                            {data.ZoningDescription
                              ? data.ZoningDescription
                              : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Subdivision:</div>
                          <div className="col-7">
                            {data.SubdivisionName
                              ? data.SubdivisionName
                              : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">MLS Number:</div>
                          <div className="col-7">
                            {data.ListAgentMlsId
                              ? data.ListAgentMlsId
                              : 'Not Specified'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  {/***** ADDITIONAL FEATURES *****/}
                  <TabPanel>
                    <h3 className="py-4 text-center">Additional Features</h3>
                    <div className="row listing-detail-table">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-5">Cooling:</div>
                          <div className="col-7">
                            {data.Cooling ? data.Cooling : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Appliances:</div>
                          <div className="col-7">
                            {data.Appliances
                              ? data.Appliances.join(', ')
                              : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Fireplace Location:</div>
                          <div className="col-7">
                            {data.FireplaceLocation
                              ? data.FireplaceLocation
                              : 'None'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Construction Type:</div>
                          <div className="col-7">
                            {data.ConstructionType
                              ? data.ConstructionType
                              : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Style:</div>
                          <div className="col-7">
                            {data.ArchitecturalStyle
                              ? data.ArchitecturalStyle
                              : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Water Source:</div>
                          <div className="col-7">
                            {data.WaterSource
                              ? data.WaterSource
                              : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Exterior Features:</div>
                          <div className="col-7">
                            {data.ExteriorFeatures
                              ? data.ExteriorFeatures
                              : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Parking:</div>
                          <div className="col-7">
                            {data.Parking ? data.Parking : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Fee Includes:</div>
                          <div className="col-7">
                            {data.FeeIncludes
                              ? data.FeeIncludes
                              : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Gas Type:</div>
                          <div className="col-7">
                            {data.GasType ? data.GasType : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">HVAC:</div>
                          <div className="col-7">
                            {data.HVAC ? data.HVAC : 'Not Specified'}
                          </div>
                        </div>
                        {/* <div className="row">
													<div className="col-5">Laundry:</div>
													<div className="col-7">
														{data.Laundry ? data.Laundry : 'Not Specified'}
													</div>
													<div className="col-7">None Known</div>
												</div> */}
                        <div className="row">
                          <div className="col-5">Fireplace Construction:</div>
                          <div className="col-7">
                            {data.FireplaceConstruction
                              ? data.FireplaceConstruction
                              : 'None'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Total Acres:</div>
                          <div className="col-7">
                            {data.LotSizeAcres
                              ? data.LotSizeAcres
                              : 'Not Specified'}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-5">View</div>
                          <div className="col-7">
                            {data.View ? data.View : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Basement:</div>
                          <div className="col-7">
                            {data.Basement ? data.Basement : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Laundry:</div>
                          <div className="col-7">
                            {data.Laundry ? data.Laundry : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Fireplace Features:</div>
                          <div className="col-7">
                            {data.FireplaceFeatures
                              ? data.FireplaceFeatures.join(', ')
                              : 'Not Specified'}
                          </div>
                        </div>
                        {/* <div className="row">
													<div className="col-5">Fireplace Construction:</div>
													<div className="col-7">Brick, Stucco</div>
												</div> */}
                        <div className="row">
                          <div className="col-5">Roof:</div>
                          <div className="col-7">
                            {data.Roof ? data.Roof.join(', ') : 'Not Specified'}
                          </div>
                        </div>
                        {/* <div className="row">
													<div className="col-5">View:</div>
													<div className="col-7">Mountain View</div>
												</div> */}
                        {/* <div className="row">
													<div className="col-5">Basement:</div>
													<div className="col-7">
														Finished, FULL, GARDEN LEVEL
													</div>
												</div> */}
                        <div className="row">
                          <div className="col-5">Topography:</div>
                          <div className="col-7">
                            {data.Topography
                              ? data.Topography
                              : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Parking Spaces:</div>
                          <div className="col-7">
                            {data.ParkingSpaces ? data.ParkingSpaces : 'None'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Fuel:</div>
                          <div className="col-7">
                            {data.Fuel ? data.Fuel : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Sewer:</div>
                          <div className="col-7">
                            {data.Sewer ? data.Sewer : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Site Features:</div>
                          <div className="col-7">
                            {data.SiteFeatures
                              ? data.SiteFeatures
                              : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Sale Rent:</div>
                          <div className="col-7">
                            {data.SaleRent ? data.SaleRent : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Dues Frequency:</div>
                          <div className="col-7">
                            {data.DuesFrequency
                              ? data.DuesFrequency
                              : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Partial Ownership:</div>
                          <div className="col-7">
                            {data.PartialOwnership
                              ? data.PartialOwnership
                              : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Under Contract Date:</div>
                          <div className="col-7">
                            {data.UnderContractDate
                              ? data.UnderContractDate
                              : 'Not Specified'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  {/***** AREA FACTS *****/}
                  <TabPanel>
                    <h3 className="py-4 text-center">
                      Colorado Springs, CO Area Facts
                    </h3>
                    <div className="row listing-detail-table">
                      <div className="col-md-6 offset-md-3">
                        <div className="row">
                          <div className="col-5">
                            Average Travel Time to Work:
                          </div>
                          <div className="col-7 text-right">
                            {data.AverageTravelTimetoWork
                              ? data.AverageTravelTimetoWork
                              : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Median Income:</div>
                          <div className="col-7 text-right">
                            {data.MedianIncome
                              ? data.MedianIncome
                              : 'Not Specified'}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">Average SAT Score:</div>
                          <div className="col-7 text-right">
                            {data.AverageSATScore
                              ? data.AverageSATScore
                              : 'Not Specified'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  {/***** REQUEST MORE INFO *****/}
                  <TabPanel>
                    <h3 className="py-4 text-center">
                      Request More Information
                    </h3>
                    <div className="row">
                      <div className="col-md-6 offset-md-3">
                        <WebriQForm
                          name="Request More Information"
                          className="text-left"
                          data-form-id="5cb030d41b6d8702bb063dcc"
                        >
                          <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                              className="form-control"
                              type="text"
                              required=""
                              name="name"
                            />
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="email">Email address</label>
                                <input
                                  className="form-control"
                                  type="email"
                                  required=""
                                  name="email"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="phone">Phone number</label>
                                <input
                                  className="form-control"
                                  type="tel"
                                  name="phone"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea
                              className="form-control"
                              rows="4"
                              required=""
                              name="message"
                              placeholder="I would like to get more information regarding
															listing: 1539 Yellow Tail Drive, Colorado Springs,
															CO 80921."
                            />
                          </div>
                          <div className="form-group">
                            <div className="webriq-recaptcha mb-4" />
                          </div>
                          <div className="text-center">
                            <button className="alink hvr-shadow" type="submit">
                              Send Inquiry
                            </button>
                          </div>
                        </WebriQForm>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default ListingDetails

export const query = graphql`
  query listingQuery($id: String!) {
    sanityListing(id: { eq: $id }) {
      id
      UnparsedAddress
      FireplaceLocation
      City
      VirtualTour
      StateOrProvince
      BathroomsTotalInteger
      BedroomsTotal
      LotSizeSquareFeet
      LotSizeAcres
      description
      Agent
      YearBuilt
      StandardStatus
      BathroomsFull
      SchoolDistrictFirst
      SchoolDistrictSecond
      SchoolDistrictThird
      Country
      Taxes
      TaxYear
      AssociationFee
      SubdivisionName
      ListAgentMlsId
      ZoningDescription
      Cooling
      Appliances
      ArchitecturalStyle
      WaterSource
      ExteriorFeatures
      Parking
      FeeIncludes
      GasType
      HVAC
      Laundry
      ConstructionType
      FireplaceConstruction
      FireplaceFeatures
      View
      Basement
      Topography
      ParkingSpaces
      Fuel
      Sewer
      SiteFeatures
      SaleRent
      DuesFrequency
      PartialOwnership
      UnderContractDate
      Floor
      AverageTravelTimetoWork
      MedianIncome
      AverageSATScore
      Roof
      Media {
        asset {
          id
          url
        }
      }
    }
  }
`
